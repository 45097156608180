<template>
  <v-layout wrap>
    <v-flex xs8 style="padding: 20px 10px 20px 20px">
      <system-table
        :loading="loading"
        :title="$t('workspace.title', locale)"
        :headers="[
          { text: $t('workspace.name', locale), value: 'ident' },
          { text: 'ID', value: 'ID' },
          { text: $t('workspace.users', locale), value: 'users' },
        ]"
        :onGetData="handleGetData"
        :tableData="workspaces"
        :onSearch="handleSearch"
        :onEditItem="handleEdit"
        :onDeleteItem="handleDelete"
        :startIndex="startIndex"
        :itemsPerPage="itemsPerPage"
        :count="count"
        :hideAddButton="true"
      />
    </v-flex>
    <v-flex xs4 style="padding: 20px 20px 20px 10px">
      <v-card
        style="min-height:calc(100vh - 104px);"
        elekvation="0"
      >
        <v-card-text>
          <v-btn
            v-if="!showForm"
            @click="handleEdit(null)"
            block
            color="primary"
          >
            {{ $t('common.add', locale) }}
          </v-btn>
          <workspace-form
            :editedID="editedID"
            :showForm="showForm"
            :onDelete="handleDelete"
            :onSave="handleSave"
            :onClose="handleCloseForm"
          />
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
  /*const defaultItem = {
    Name: '',
    Users: [],
  }*/

  import { mapState } from 'vuex'
  import api from '@/services/api'
  import SystemTable from '@/components/systemTable/Index'
  import WorkspaceForm from './WorkspaceForm'
  export default {
    components: {
      SystemTable,
      WorkspaceForm
    },
    data: () => ({
      loading: false,
      search: '',
      workspaces: [],
      startIndex: 0,
      itemsPerPage: 10,
      count: null,
      editedID: null,
      showForm: null
    }),
    computed: {
      ...mapState('app',['locale']),
    },
    mounted () {
      this.handleGetData()
    },
    methods: {
      handleSearch (v) {
        this.search = v
        this.handleGetData()
      },
      handleGetData (startIndex) {
        this.workspaces = []
        this.loading = true
        if (startIndex >=0 ) this.startIndex = startIndex
        api.getAll ('booking', 'v1/system/workspaces/', this.startIndex, this.itemsPerPage, this.search)
          .then(response => {             
            this.count = response.count
            this.workspaces = response.data
            this.loading = false
          })
      },
      /*handleGetItem (workspaceID) {
        api.getItem ('booking', 'v1/system/workspaces/', workspaceID)
        .then(response => {
          this.editedItem = response
          this.showForm = true
        })
      },*/
      handleEdit (v) {
        this.editedID = v
        this.showForm = true
        /*
        if (v) this.handleGetItem(v)
        else {
          this.editedItem = JSON.parse(JSON.stringify(defaultItem))
          this.showForm = true
        }*/
      },
      handleDelete (v) {
        if (v) 
          api.deleteItem ('booking', 'v1/system/workspaces/', v)
          .then(() => {                      
            this.handleGetData(this.startIndex)
            this.handleCloseForm()
          })
      },
      handleSave (v) {
        if (!v.RenameFolders || v.RenameFolders.length === 0) v.RenameFolders = null
        if (v.ID)
          api.updateItem ('booking', 'v1/system/workspaces/', v.ID, v)
          .then(() => {          
            this.handleGetData(this.startIndex)
            this.handleCloseForm()
          })  
        else
          api.addItem ('booking', 'v1/system/workspaces/', v)
          .then(() => {          
            this.handleGetData(this.startIndex)
            this.handleCloseForm()
          })       
      },
      handleCloseForm () {
        this.showForm = false
      },
    }
  }
</script>

